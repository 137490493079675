<template>
    <div class="ResponsiveContentArea">
        <template v-if="isFHT()">
            <div class="titleArea">
                <div class="titleContainer">
                    <img class="titleBackgound" src="@/assets/icons/titleBackgoundMoreInfo.svg"/>
                    <img @click="home()" class="mainStatusIcon" :src="iconStatus" />
                    <div class="titlesContainer">
                        <div class="infoTitle textNormal">
                            <div class="textSmall">{{device.client.name}}</div>
                            <div class="textVerySmall lightGrey">{{device.type.name}}</div>
                            <div class="fadeLongName"></div>
                        </div>
                        <div class="moreInfoTitle textNormal">
                            <div class="textVerySmall lightGrey">{{device.description}} - {{device.unity.name}}</div>
                            <div class="textVerySmall lightGrey">{{device.unity.city}}-{{device.unity.state}}, {{device.unity.country.name}}</div>
                            <div class="fadeLongName"></div>
                        </div>
                    </div>
                </div>
                <div class="accessTypeArea">
                    <div class="containerAccessType">
                        <div class="btnAccessTypeArea">
                            <router-link v-if="showSSH()" class="btnAccessType" :to="{ name:'SSH', params: { device:device }}">
                                <img class="btnAccessTypeIcon" src="@/assets/icons/AccessType/ssh.svg" alt="">
                                <div class="textSmall labelAccessType">SSH</div>
                            </router-link>
                            <router-link v-if="showRDP()" class="btnAccessType" :to="{ name:'RDP', params: { device:device }}">
                                <img class="btnAccessTypeIcon" src="@/assets/icons/AccessType/rdp.svg" alt="">
                                <div class="textSmall labelAccessType">RDP</div>
                            </router-link>
                            <router-link v-if="showFTP()" class="btnAccessType" to="/DeviceAccess/FTP">
                                <img class="btnAccessTypeIcon" src="@/assets/icons/AccessType/ftp.svg" alt="">
                                <div class="textSmall labelAccessType">FTP</div>
                            </router-link>
                        </div>
                        <div v-if="showSSH() || showRDP() || showFTP()" class="labelArea">
                            <div class="textSmall labelAcess">Tipo de Acesso: </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mainBar">
                <div class="statusBar">
                    <div v-for="tab in tabs" :key="tab.id"  @click="setCurrentTab(tab)" class="tab textSmall" :class="{currentTab:currentTab.id == tab.id}">
                        {{tab.name}}
                    </div>
                </div>
            </div>
            <div class="row content">
                <div class="mainArea">
                    <Status v-if="currentTab.id != 0" :currentTab="currentTab" :device="device"></Status>
                    <DashBoard v-if="currentTab.id == 0" :device="device" @setCurrentTab="setCurrentTab($event)"></DashBoard>
                </div>
            </div>
        </template>

        <template v-else-if="isESP()">
            
            <div class="titleArea">
                <div class="titleContainer">
                    <img class="titleBackgound" src="@/assets/icons/titleBackgoundMoreInfo.svg"/>
                    <img @click="home()" class="mainStatusIcon" :src="iconStatus" />
                    <div class="titlesContainer">
                        <div class="infoTitle textNormal">
                            <div class="textSmall">{{device.client.name}}</div>
                            <div class="textVerySmall lightGrey">{{device.type.name}}</div>
                            <div class="fadeLongName"></div>
                        </div>
                        <div class="moreInfoTitle textNormal">
                            <div class="textVerySmall lightGrey">{{device.description}} - {{device.unity.name}}</div>
                            <div class="textVerySmall lightGrey">{{device.unity.city}}-{{device.unity.state}}, {{device.unity.country.name}}</div>
                            <div class="fadeLongName"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mainBar">
                <div class="statusBar">
                    <div v-for="tab in espTabs" :key="tab.id"  @click="setCurrentTab(tab)" class="tab textSmall" :class="{currentTab:currentTab.id == tab.id}">
                        {{tab.name}}
                    </div>
                </div>
            </div>

            <div class="row content">
                <div class="mainArea">
                    <StatusESP v-if="currentTab.id != 0" :currentTab="currentTab" :device="device"></StatusESP>
                    <KeepAlive>
                        <DashBoardESP v-if="currentTab.id == 0" :device="device" @setCurrentTab="setCurrentTab($event)"></DashBoardESP>
                    </KeepAlive>
                </div>
            </div>
            

        </template>

        <template v-else>
            <div class="titleArea">
                <div class="titleContainer">
                    <img class="titleBackgound" src="@/assets/icons/titleBackgoundMoreInfo.svg"/>
                    <div class="titleOtherDevice textNormal">
                        <div v-if="device.type.id == 7"> Central de {{device.type.name}}</div>
                        <div v-else>Central {{device.type.name}}</div>
                    </div>
                </div>
            </div>
            <Others :device="device" :accessLevel="accessLevel" :services="services"></Others>
        </template>
    </div>
</template>

<script>
    import router from '@/router/index'
    import {STATUS_COUNT_INDEX, CENTRAL_TYPE} from '@/sys/Model/Device/Device';
    import BackEnd from "@/sys/BackEnd/BanckEnd";
    import DashBoard from '@/views/content/Home/DeviceAccess/DashBoard/DashBoard.vue'
    import DashBoardESP from '@/views/content/Home/DeviceAccess/DashBoard/DashBoardESP.vue'
    import Others from '@/views/content/Home/DeviceAccess/Others.vue'
    import Status from '@/views/content/Home/DeviceAccess/Status/Status.vue'
    import StatusESP from '@/views/content/Home/DeviceAccess/Status/StatusESP.vue'
    import {AccessLevel} from '@/sys/Model/Permissions/AccessLevel'
    import {Services} from '@/sys/Model/Permissions/Services'

    export default {
        components:{ Status, DashBoard, Others, DashBoardESP, StatusESP },
        methods:{
            isFHT(){
                return this.device.type.id <= CENTRAL_TYPE.MB15_8 || this.device.type.id == CENTRAL_TYPE.FHT7_2 || this.device.type.id == CENTRAL_TYPE.FHT7_8
            },
            home(){
                if(this.currentTab.id != 0){
                    this.currentTab = {name: 'Painel', id: 0, color: null}
                }
            },
            setCurrentTab(tab){
                this.currentTab = tab
            },
            close(){
                this.$router.push('/ListOfDevices')
            },
            compareService(service) {
                let services = this.services;
                for(let i=0; i < services.length; i++) {
                    if(services[i].$numberInt == service) {
                        return true;
                    }
                }
                return false;
            },
            showSSH(){
                if (this.accessLevel <= AccessLevel.DESENVOLVIMENTO && this.compareService(Services.SSH)) {
                    return true;
                } else {
                    return false;
                }
            },
            showRDP(){
                if (this.accessLevel <= AccessLevel.CLIENTE && this.accessLevel != AccessLevel.PRODUCAO && this.accessLevel != AccessLevel.VENDAS
                    && this.compareService(Services.RDP)) {
                    return true;
                } else {
                    return false;
                }
            },
            showFTP(){
                if (this.accessLevel <= AccessLevel.CLIENTE && this.accessLevel != AccessLevel.PRODUCAO && this.accessLevel != AccessLevel.VENDAS
                    && this.compareService(Services.FTP)) {
                    return true;
                } else {
                    return false;
                }
            },
            isESP(){
                return this.device.type.id === CENTRAL_TYPE.GATEWAY;
            }
        },
        data() {
            return {
                iconStatus: null,
                tabs: [
                    {name: 'Painel', id: 0, color: null},
                    {name: 'Fogo', id: 1, color: '#ff2a2a'},
                    {name: 'Falha', id: 2, color: '#ffc22d'},
                    {name: 'Bateria', id: 3, color: '#4375EF'},
                    {name: 'Supervisão', id: 4, color: '#ff7700'},
                    {name: 'Desabilitação', id: 5, color: '#666666'},
                ],
                espTabs: [
                    {name: 'Painel', id: 0},
                ],
                currentTab: {name: 'Painel', id: 0, color: null},
                accessLevel: BackEnd.getCurrentUserAccess(),
                services: BackEnd.getCurrentUserServices(),
            }
        },
        props: {
            device:{
                type: Object,
                default: null
            }
        },
        created(){
            if(this.device == null){
                console.log("Redirecionando por falta de parametro");
                router.push({path:'/ListOfDevices'});
            }

            console.log(this.device)
        },
        watch:{
            $props:{
                handler(){
                    if(!this.device.status){
                        this.device.status={ counters: [0,0,0,0,0,0,0,0,0,0,0,0,0,0]};
                    }else{
                        if(!this.device.status.counters){
                            this.device.status.counters=[0,0,0,0,0,0,0,0,0,0,0,0,0,0];
                        }
                    }

                    if(this.isESP()){
                        if(this.device.systemInfo.fireAlert){
                            this.iconStatus=require("@/assets/icons/redFire.svg");
                        } else if(this.device.systemInfo.failureAlert){
                            this.iconStatus=require("@/assets/icons/warning.svg");
                        }else{
                            this.iconStatus=require("@/assets/icons/checked.svg");
                        }
                    }else{
                        if(this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_FIRE]>0){
                            this.iconStatus=require("@/assets/icons/alert/fire.svg");
                        }else{
                            if(this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_FAILURE]>0){
                                this.iconStatus=require("@/assets/icons/alert/failure.svg");
                            }else{
                                if(this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_LOW_BATTERY]>0){
                                    this.iconStatus=require("@/assets/icons/alert/lowBattery2.svg");
                                }else{
                                    if(this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_SUPERVISION]>0){
                                        this.iconStatus=require("@/assets/icons/alert/supervision.svg");
                                    }else{
                                        if(this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_DISABLE]>0){
                                            this.iconStatus=require("@/assets/icons/alert/disabled2.svg");
                                        } else {
                                            this.iconStatus=require("@/assets/icons/alert/idle.svg");
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                deep:true,
                immediate:true
            }
        }
    }
</script>

<style scoped>
    .ResponsiveContentArea{
        display: flex;
        flex-flow: column;
        height: 100%;
        width: 100vw;
        background-color:#292d32
    }
    .ResponsiveContentArea .row {
        position: relative;
    }
    .ResponsiveContentArea .row.content {
        flex: 1 1 auto
    }
    .containerMainArea{
        flex: 1 1 auto
    }
    .mainArea{
        position: absolute;
        top: 0px;
        right: 10px;
        left: 10px;
        bottom: 10px;
        background-color: #292d32;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.6);
    }
    .containerAccessType{
        position: relative;
        height: 100%;
    }
    .labelArea{
        width: 150px;
        height: 100%;
        float: right
    }
    .btnAccessTypeArea{
        height: 100%;
        float: right
    }
    .btnAccessTypeIcon{
        position: absolute;
        left: 22px;
        top: 14px;
        width: 36px;
    }
    .labelAccessType{
        position: absolute;
        right: 30px;
        top: 24px;
    }
    .btnAccessType{
        position: relative;
        display: inline-block;
        padding: 12px;
        margin-left: 15px;
        height: 65px;
        width: 145px;
        border: none;
        background-color:#36393F;
        text-decoration:none;
        text-align: center;
        border-radius: 4px 4px 4px 4px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.6);
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
    }
    .accessTypeArea{
        position: relative;
        width: 641px;
        margin-right: 20px;
        float: right;
        height: 95px
    }
    .labelAcess{
        position: absolute;
        text-align: right;
        height: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    .mainBar{
        height: 60px;
        width: 100%;
        white-space: nowrap;
        z-index: 2;
    }
    .statusBar{
        display: inline-block;
        height: 60px;
        padding: 10px 0 0 10px;
        overflow-x: hidden;
        overflow-y: hidden;
        scroll-behavior: auto
    }
    .tab{
        display: inline-block;
        width: 190px;
        height: 50px;
        padding: 0 0 0 10px;
        margin-right: 4px;
        line-height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 8px 8px 0 0;
        background-color: #1e2227;
        color: #808080;
        box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.2), inset 0 -30px 15px -20px rgba(0, 0, 0, 0.4);
        cursor: pointer
    }
    .currentTab{
        background-image: linear-gradient(#36393f, #292d32);
        box-shadow: 0px -7px 5px 0px rgba(0, 0, 0, 0.2), inset 0 -25px 15px -20px rgba(0, 0, 0, 0.0);
        color: white
    }
    .mainStatusIcon{
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translate( 0px, -50%);
        height: 60px;
        cursor: pointer
    }
    .titleContainer{
        position: relative;
        margin-bottom: 5px;
        float: left;
    }
    .titleArea{
        display: inline-block;
        margin: 12px 0 0 10px;
        width: 100%
    }
    .titlesContainer{
        position: absolute;
        height: 40px;
        top: 50%;
        left: 100px;
        right: 26%;
        transform: translate( 0px, -50%);
    }
    .titleOtherDevice{
        display: grid;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        vertical-align: middle;
        left: 50px;
    }
    .infoTitle{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 48%;
        overflow: hidden;
        white-space: nowrap;
    }
    .moreInfoTitle{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 48%;
        right: 0px;
        overflow: hidden;
        white-space: nowrap;
    }
    .titleBackgound{
        width: 700px;
    }
    .btnArea{
        position: fixed;
        right: 10px;
        bottom: 10px;
    }
    .ResponsiveContentBoard{
        position: relative;
        display: inline-block;
    }
    .ResponsiveLabelMenu{
        margin-left: 0px ;
    }
    .fadeLongName{
        position: absolute;
        top: 0;
        right: 0px;
        width: 30px;
        height: 100%;
        background-image: linear-gradient(to right, #1a1a2200, #1a1a22 80%);
    }
    @media(max-width: 1600px){
        .titleBackgound{
            width: 550px;
        }
        .accessTypeArea{
            height: 50px;
        }
        .containerAccessType{
            float: left;
        }
        .btnAccessType{
            height: 50px;
        }
        .btnAccessTypeIcon{
            left: 18px;
            top: 10px;
            width: 30px;
        }
        .labelAccessType{
            right: 30px;
            top: 16px;
        }
        .titleArea{
            width: 100%
        }
        .mainBar{
            height: 55px;
        }
        .tab{
            width: 165px;
            height: 45px;
            line-height: 45px;
        }
    }
    @media(max-width: 1450px){
        .accessTypeArea{
            width: 460px;
            margin-right: 10px;
            height: 65px;
            float: right
        }
        .labelArea{
            width: 110px;
        }
        .btnAccessType{
            width: 95px;
            height: 40px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
        }
        .btnAccessTypeIcon{
            left: 12px;
            top: 8px;
            width: 24px;
        }
        .labelAccessType{
            right: 15px;
            top: 15px;
        }
        .mainBar{
            height: 45px;
        }
        .tab{
            width: 145px;
            height: 35px;
            line-height: 35px
        }
        .mainStatusIcon{
            height: 50px;
        }
    }
    @media(max-width: 1200px){
        .accessTypeArea{
            float: left
        }
    }
    @media(max-width: 1000px){
        .titleBackgound{
            width: 100%;
        }
        .titleContainer{
            width: 100%;
        }
        .mainStatusIcon{
            height: 70%;
        }
        .accessTypeArea{
            float: left;
        }
        .mainBar{
            width: 100vw;
        }
        .statusBar{
            width: 100%;
            padding-right: 10px;
        }
    }
    @media(max-height: 600px){
        .mainArea{
            height: 450px;
        }
        .ResponsiveContentArea{
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    
    @media(max-width: 825px){
        .titleArea{
            margin: 5px 0 0 5px;
        }
        .statusBar{
            padding: 10px 0 0 5px;
        }
        .labelArea{
            width: 95px;
        }
        .btnAccessType{
            height: 35px;
            width: 80px;
        }
        .btnAccessTypeIcon{
            left: 10px;
            top: 8px;
            width: 20px;
        }
        .labelAccessType{
            right: 12px;
            top: 11px;
        }
        .btnAccessTypeArea{
            width: 350px;
            height: 100%
        }
        .accessTypeArea{
            height: 32px;
            width: 450px;
        }
        .mainBar{
            height: 40px;
        }
        .tab{
            width: 100px;
            height: 30px;
            line-height: 30px
        }
        .mainArea{
            right: 5px;
            left: 5px;
            bottom: 5px
        }
        .ResponsiveContentArea{
            overflow-y: auto;
        }
        .ResponsiveContentArea .row.content{
            width: 100vw;
        }
    }
    @media(max-width: 720px){
        .titlesContainer{
            left: 18%;
            right: 26%;
        }

        .tab{
            width: 22%;
        }
    }
</style>